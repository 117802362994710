BRAVEWRITER = {

  common : {
    init : function() {
      this.loadAccountNav();
      this.initBreadcrumbs();
      this.initMobileNav();
      this.initModals();
      this.initTabs();
      this.initTooltips();
      this.initToggleSwitches();
      this.initSummaryDetails();
      this.initToggleElements();
      this.loadAnnouncementBar();
      this.updateRegSymbol();
      this.initCKEditorEmbeds();

      BRAVEWRITER.scroller.init();
      BRAVEWRITER.imageGallery.init();
      
      setTimeout(this.cleanURL(), 500);
    },
    
    finalize : function() {},

    initMobileNav : function() {
      $("#mobile-nav").mmenu({
        offCanvas: {
          position  : "right"
        },
        extensions: ["theme-dark"]
      },{
        offCanvas: {
          pageSelector: ".mm-wrapper"
        }
      });
    },

    loadAccountNav : function() {
      let xhr = new XMLHttpRequest();
      let currentHost = window.location.hostname;
      if (currentHost.includes('store.bravewriter.com') || currentHost.includes('blog.bravewriter.com')) {
        var url = 'https://bravewriter.com/account/nav';
      } else {
        var url = '/account/nav';
      }
  
      xhr.open('GET', url, true);
      xhr.responseType = 'text';
      xhr.onload = () => {
        let status = xhr.status;
        let navNode = document.querySelectorAll('[data-load=my-account]');
        let navFallback = '<a href="/account">My Account</a>';
  
        navNode.forEach(item => {
          if (item.classList.contains('nav-account-mobile')) {
            item.innerHTML = navFallback;
          } else if (status == 200) {
            item.innerHTML = xhr.responseText;
          } else {
            item.innerHTML = `<ul><li>${navFallback}</li></ul>`;
          };
        });
      };
      xhr.send();
    },

    loadAnnouncementBar : function() {
      const parser = new DOMParser();
      const xhr = new XMLHttpRequest();
      const currentHost = window.location.hostname;
      const domain = (currentHost.includes('store.bravewriter.com') || currentHost.includes('blog.bravewriter.com')) ? 'https://bravewriter.com/' : '/';
      const url = domain + 'announcement-bar';
  
      xhr.open('GET', url, true);
      xhr.responseType = 'text';
      xhr.onload = () => {
        const status = xhr.status;
        const wrapper = document.querySelector('.mm-wrapper');
        
        if(xhr.responseText) {
          const anncWrapper = document.createElement('div');
          // anncWrapper.style.overflow = 'hidden';
          // anncWrapper.style.transition = 'all .75s';
          // anncWrapper.style.maxHeight = '0';
          anncWrapper.innerHTML = xhr.responseText;
          wrapper.prepend(anncWrapper);

          // setTimeout(() => { anncWrapper.style.maxHeight = '100px' },  50);
        }
      };
      xhr.send();
    },

    initBreadcrumbs : function() {
      const breadcrumbs = document.querySelector('.breadcrumbs');
      const breadcrumbsToggle = document.querySelector('.breadcrumbs-toggle');

      if(breadcrumbs && breadcrumbsToggle) {
        breadcrumbsToggle.addEventListener("click", (e) => {
          breadcrumbs.classList.toggle('open');
        });
      }
    },

    updateRegSymbol : function() {
      var walker = document.createTreeWalker(document.querySelector('body'), NodeFilter.SHOW_TEXT);
      var node;
      var containsReg = [];
      while (node = walker.nextNode()) {
        if (node.nodeValue.includes("Writer®")) {
          containsReg.push(node.parentNode);
        }
      }
      containsReg.forEach((node) => {
        node.innerHTML = node.innerHTML.replace("Writer®", "Writer<sup class='reg'>&reg;</sup>");
      });
    },

    initTabs : function() {
      if($('.tabs').length) {

        $('.tabs').each(function() {
          var defaultTab = $(this).data('default'),
              firstTab = $(this).find('li:first-child > a');

          // Check if URLs or if IDs. Only initiate if IDs
          if (firstTab.attr('href').substring(0,1) != '#') return;

          $(this).find('li > a').on('click', function(e) {
            e.preventDefault();
            $(this).tab('show');
          });

          // Preselect a tab: window hash, default, or first
          var selectTabId = firstTab.attr('href');
          if(window.location.hash != '') {
            if ($(this).find('a[href="' + window.location.hash + '"]').length) {
              selectTabId = window.location.hash;
            }
          } else if(defaultTab) {
            selectTabId = '#' + defaultTab;
          }
          $(this).find('a[href="' + selectTabId + '"]').tab('show');
        });

      }
    },

    initModals : function() {
      const baseURL = document.location.origin + document.location.pathname + document.location.search;
      const modalCallbacks = {
        beforeOpen: function() {
          this.st.mainClass = this.st.el.attr('data-effect');
        },
        open: function() {
          let updatedUrl = baseURL + this.currItem.src;
          history.replaceState({},'', updatedUrl);

          // Initialize any scrollers/galleries within the modal
          BRAVEWRITER.scroller.init(); 
          BRAVEWRITER.imageGallery.init();
        },
        close: function() {
          history.replaceState({},'', baseURL);
        }
      }

      $('.modal-link').magnificPopup({
        callbacks: modalCallbacks,
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
      });

      $('.modal-link-ajax').magnificPopup({
        type: 'ajax',
        alignTop: true,
        overflowY: 'scroll',
        callbacks: modalCallbacks,
        midClick: true
      });

      $('.modal-link-image').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        callbacks: modalCallbacks,
        midClick: true
      });


      // If window hash, check if it's a modal and, if so, open it
      if(window.location.hash != '') {
        if ($('a[href="' + window.location.hash + '"].modal-link').length) {
          $('a[href="' + window.location.hash + '"].modal-link').click();
          BRAVEWRITER.scroller.init(); // Initialize any scrollers within the modal
        }
        if ($('a[href="' + window.location.hash + '"].modal-link-ajax').length) {
          $('a[href="' + window.location.hash + '"].modal-link-ajax').click();
          BRAVEWRITER.scroller.init(); // Initialize any scrollers within the modal
        }
      }
    },

    initTooltips : function() {

      // HTML Tooltips
      $('.has-interactive-tooltip').each(function() {
        $(this).tooltipster({
          trigger: 'click',
          maxWidth: 400,
          onlyOne: true,
          interactive: true,
          theme: ['tooltipster-light', 'tooltipster-light-customized']
        });
      });

      // Standard tooltips
      $('.has-tooltip').tooltipster({
        theme: 'tooltipster-default'
      });

      // Standard tooltips
      // $('.has-interactive-tooltip').tooltipster({
      //   theme: 'tooltipster-default',
      //   interactive: true
      // });

    },

    initToggleSwitches : function() {
      // submit specified toggle switches on change
      $('.toggle-switch[data-auto-submit="true"]').change(function() {
        var $this = $(this),
            $form = $this.parents('form'),
            action = $form.find('input[name=action]').val(),
            isChecked = $this.prop('checked');

        $.post(action, $form.serialize())
          .done(function(data) {
            var ttContent = isChecked ? 'You will now recieve email updates for this post' : 'You will no longer receive emails for this post';
            $form.tooltipster()
                 .tooltipster('content', ttContent)
                 .tooltipster('open');
            setTimeout(function() { $form.tooltipster('close'); }, 3000);
          })
          .fail(function(data) {
            $form.tooltipster()
                 .tooltipster('content', "Error saving follow preferences!")
                 .tooltipster('open');
            setTimeout(function() { $form.tooltipster('close'); }, 3000);
            console.log(data);
          });
      });  
    },

    initCarousel: function() {
      const navigationControlsTemplate = document.querySelector('#carousel-controls');
      const carouselEl = document.querySelector('.carousel');
      if(carouselEl) {
        const carousel = new Carousel({
          root: carouselEl,
          navigationControls: navigationControlsTemplate.content.cloneNode(true),
        });
      }
    },

    setCookie : function(name, value, days) {
      var d = new Date();
      d.setTime(d.getTime() + 24*60*60*1000*days);
      document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    },

    getCookie : function(name) {
      var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return v ? v[2] : null;
    },

    initSummaryDetails : function() {
      $(document).on('click', '[data-toggle="details"]', function() {
        var $this = $(this),
            $details = $('details');

        if ($this.data('target') == 'all') {
          if ($this.data('direction') == 'open') {
            $details.attr('open', '');
          } else {
            $details.removeAttr('open');
          }
        }
      });
    },

    // Allow links to toggle elements visibility
    initToggleElements : function() {
      $(document).on('click change', '[data-toggle="elements"]', function(e) {
        var $this = $(this),
            tagName = $this.prop('tagName').toLowerCase();

        if(tagName == 'a') e.preventDefault();

        // If the elements to toggle are set in the tag (link) then create an array of them
        if($this.data('toggle-elements')) {
          var toggle_array = $this.data('toggle-elements').split(',');
        }
        if($this.data('toggle-elements-show')) {
          var show_array = $this.data('toggle-elements-show').split(',');

          // Remember this setting for 2.4 hours
          var remembered_show_elements = BRAVEWRITER.common.getCookie('show_elements');
          remembered_show_array = (remembered_show_elements) ? remembered_show_elements.split(',') : [];
          combined_show_array = remembered_show_array.concat(show_array);
          BRAVEWRITER.common.setCookie('show_elements', combined_show_array.join(',') , .1);
        }
        if($this.data('toggle-elements-hide')) {
          var hide_array = $this.data('toggle-elements-hide').split(',');

          // Remember this setting for 2.4 hours
          var remembered_hide_elements = BRAVEWRITER.common.getCookie('hide_elements');
          remembered_hide_array = (remembered_hide_elements) ? remembered_hide_elements.split(',') : [];
          combined_hide_array = remembered_hide_array.concat(hide_array);
          BRAVEWRITER.common.setCookie('hide_elements', combined_hide_array.join(',') , .1);
        }

        // If a select tag, each option may toggle different elements
        if(tagName == 'select') {
          if ($this.find(':selected').data('toggle-elements-show')) {
            var show_array = $this.find(':selected').data('toggle-elements-show').split(',');
          }
          if ($this.find(':selected').data('toggle-elements-hide')) {
            var hide_array = $this.find(':selected').data('toggle-elements-hide').split(',');
          }
        }

        if(toggle_array) {
          for (var i = 0; i < toggle_array.length; i++) {
            var $el = $(toggle_array[i]);
            $el.toggle();
          }
        }

        if(show_array) {
          for (var i = 0; i < show_array.length; i++) {
            var $el = $(show_array[i]);
            $el.show();
          }
        }

        if(hide_array) {
          for (var i = 0; i < hide_array.length; i++) {
            var $el = $(hide_array[i]);
            $el.hide();
          }
        }

      });
    },

    initCKEditorEmbeds : function() {
      document.querySelectorAll('oembed[url]').forEach(element => {
        var embedURL = element.getAttribute('url');
        var embedCode = '';
        var embedID = '';
        const embedDiv = document.createElement('div');
        embedDiv.classList.add('video-embed-hd');

        // Figure out if it's a Wistia embed (or Youtube, etc.)
        if (embedURL.includes('https://bravewriter.wistia.com/medias/')) {
          embedID = embedURL.replace('https://bravewriter.wistia.com/medias/', '');
          embedCode = `<iframe src=\"https://fast.wistia.net/embed/iframe/${ embedID }?seo=false&videoFoam=false\" allow=\"autoplay; fullscreen\" allowtransparency=\"true\" frameborder=\"0\" scrolling=\"no\" class=\"wistia_embed\" name=\"wistia_embed\" msallowfullscreen></iframe>`;
        }

        embedDiv.innerHTML = embedCode;
        element.replaceWith(embedDiv);
      });
    },

    showCookieElements: function() {
      var show_elements = BRAVEWRITER.common.getCookie('show_elements');
      
      if(show_elements) {
        var show_array = show_elements.split(',');
        for (var i = 0; i < show_array.length; i++) {
          var $el = $(show_array[i]);
          $el.show();
        }
      }
    },

    hideCookieElements: function() {
      var hide_elements = BRAVEWRITER.common.getCookie('hide_elements');
      
      if(hide_elements) {
        var hide_array = hide_elements.split(',');
        for (var i = 0; i < hide_array.length; i++) {
          var $el = $(hide_array[i]);
          $el.hide();
        }
      }
    },

    cleanURL: function() {
      let searchParams = new URLSearchParams(document.location.search);
      let trackers = ['_hsmi', '_hsenc', 'utm_campaign', 'utm_medium', 'utm_source', 'utm_content'];
      let url = new URL(window.location);

      // strip the Hubspot tracker off URL to allow comments to be submitted
      trackers.forEach((tracker) => {
        if (searchParams.get(tracker)) {
          searchParams.delete(tracker);
        }
      });

      if(searchParams.size > 0) {
        const strippedUrl = [url.origin, url.pathname, '?', searchParams.toString()].join('');
        history.replaceState(null,'',strippedUrl);
      } else {
        const strippedUrl = [url.origin, url.pathname].join('');
        history.replaceState(null,'',strippedUrl);
      }
      
    },

    startTour: function(steps, continueTour) {
      var continueTour = (continueTour === undefined) ? '' : continueTour;

      // Uses introjs.com script
      var tour = introJs();
      tour.setOptions({
        showStepNumbers: false,
        overlayOpacity: .7,
        skipLabel: 'End Tour',
        showBullets: false,
        showProgress: true,
        nextLabel: 'Next »',
        prevLabel: '« Back',
        steps: steps,
        hideNext: true,
        hidePrev: true
      });
      if (continueTour != '') {
        tour.setOption('doneLabel', 'Continue Tour »')
            .oncomplete(function() {
              window.location.href = continueTour + '?autostart_tour=true';
            });
      }
      tour.start();
    },

    showTourHints: function(hintsJson) {
      var tourHints = introJs();
      tourHints.setOptions({
        hints: hintsJson,
        hintButtonLabel: 'OK',
        hintAnimation: false,
        hintPosition: 'top-right'
      });
      tourHints.onhintclose(function() {
        tourHints.refresh(); // Ensure hints don't go away after being viewed
      });
      tourHints.addHints();
    },

    triggerModal(id) {
      $.magnificPopup.open({
        items: {
          src: '#' + id,
          type: 'inline'
        },
        modal: true
      });
    },

  },

  badges_form : {
    init : function() {
      $('.remove-badge').click(function() {
        var $checkbox = $($(this).data('remove-badge'));
        $checkbox.prop('checked', false);
        $checkbox.parents('form').submit();
      });
    }
  },


  // HSA Preview
  // ----------------------------------------------------------------
  hsa_preview : {
    getPreviewRequested : function() {
      var req = BRAVEWRITER.common.getCookie('hsa_preview_requested');
      if(req) {
        return (req.indexOf('bravewriter') !== -1);
      } else {
        return false;
      }
    },
    setPreviewRequestedCookie: function(ref) {
      // Does cookie exist?
      if(BRAVEWRITER.hsa_preview.getPreviewRequested()) {
        // console.log("cookie exists: " + BRAVEWRITER.hsa_preview.getPreviewRequested());
        return true;
      }

      // Is ref from within bravewriter?
      if(ref) {
        if(ref.indexOf('bravewriter') !== -1) {
          BRAVEWRITER.common.setCookie('hsa_preview_requested', ref, 365);
          // console.log("ref ok: " + ref);
          return true;
        }
      }

      return false;
    },
    checkPreviewLinks : function() {
      $(document).on('click', 'a[data-check-preview-requested="true"]', function() {
        // If cookie is set, allow click through, else popup request access form
        if(BRAVEWRITER.hsa_preview.getPreviewRequested()) {
          return true;
        } else {
          $('a[href="#preview-modal"]').trigger('click');
          return false;
        }
      });
    },
    addPreviewBreadcrumb : function() {
      if(BRAVEWRITER.hsa_preview.getPreviewRequested()) {
        // Add an interstitial "Preview" breadcrumb
        $('.hsa-crumb').after('<li><a href="/brave-learner-home/preview">Preview</a></li>');

        // Change back button to go to the preview page instead of /brave-learner-home
        var $goBack = $('.banner-title .back-button');
        if($goBack.attr('href') == '/brave-learner-home') {
          $('.banner-title .back-button').attr('href', '/brave-learner-home/preview');
        }
      }
    }
  },


  // Comments
  // ----------------------------------------------------------------
  community_coaching : {
    init : function() {
      // If the hash is a comment id, scroll to and highlight it
      if(window.location.hash.indexOf('#comment-') == 0) {
        BRAVEWRITER.comments.highlightComment(window.location.hash);
      }
    }
  },

  comments : {
    clearQuotedComment : function($form) {
      var $textarea = $form.find('textarea[name="fields[comment]"]'),
          storage = BRAVEWRITER.comments.storageGet($form);
      if(storage) {
        $textarea.froalaEditor('html.set', storage);
      } else {
        $textarea.froalaEditor('html.set', '');
      }
      $textarea.froalaEditor('html.cleanEmptyTags');
      BRAVEWRITER.comments.focusEditor($textarea);
    },

    loadQuotedComment : function($form, text) {
      var $textarea = $form.find('textarea[name="fields[comment]"]'),
          quotedText = '<blockquote>' + text + '</blockquote>',
          storage = BRAVEWRITER.comments.storageGet($form),
          editor = $textarea.data('froala.editor');
          
      if(storage) {
        $textarea.froalaEditor('html.set', quotedText + ' ' + storage);
        // editor.selection.setAtStart(editor.$el.get(0));
        // editor.selection.restore();
      } else {
        $textarea.froalaEditor('html.insert', quotedText + ' ');
      }
      $textarea.froalaEditor('html.cleanEmptyTags');
      // editor.selection.setAtEnd(editor.$el.get(0));
      // editor.selection.restore();
      // BRAVEWRITER.comments.focusEditor($textarea);
    },

    storageId : function($form) {
        var editorId = $form.find("input[name='elementId']").val();

        if($form.find("input[name='newParentId']").val() != '') {
            editorId += ':' + $form.find("input[name='newParentId']").val();
        }

        if ($form.find("input[name='parentId']").length > 0) {
            editorId += ':' + $form.find("input[name='newParentId']").val() + ':new';
        }

        if ($form.find("input[name='commentId']").length > 0) {
            editorId += ':' + $form.find("input[name='commentId']").val() + ':edit';
        }

        return editorId;
    },

    storageSet : function($form) {
      var editorId = BRAVEWRITER.comments.storageId($form),
          content = $form.find('textarea[name="fields[comment]"]').froalaEditor('html.get');

      // console.log("saving: " + editorId);

      if(window.localStorage) {
        localStorage.setItem(window.location.pathname + ':' + editorId, content);
      }
    },

    storageGet : function($form) {
      var editorId = BRAVEWRITER.comments.storageId($form);

      if(window.localStorage) {
        return localStorage.getItem(window.location.pathname + ':' + editorId);
      } else {
        return null;
      }
    },

    focusEditor : function($textarea) {
      // set focus to end of editor
      var editor = $textarea.data('froala.editor');
      editor.selection.setAtEnd(editor.$el.get(0));
      editor.selection.restore();

      // var editorOffset = $textarea.parents('form').offset().top;
      // window.scrollTo(0, editorOffset);
    },

    highlightComment : function(comment) {
      $comment = $(comment);
      $comment.addClass('highlight-fade');
      var commentOffset = $comment.offset().top - 100;
      window.scrollTo(0, commentOffset);
    }
  },


  // Scroller
  // ----------------------------------------------------------------
  scroller: {
    init: function() {
      const scrollerWrapper = document.querySelectorAll('.grid-scroller');

      scrollerWrapper.forEach(wrapper => {
        const items = wrapper.querySelector('.grid-scroller-items');
        const nextBtn = wrapper.querySelector('.grid-scroller-arrow-next');
        const prevBtn = wrapper.querySelector('.grid-scroller-arrow-prev');
        
        if(items.scrollWidth > wrapper.clientWidth) {
          nextBtn.addEventListener('click', BRAVEWRITER.scroller.scrollToNextItem);
          prevBtn.addEventListener('click', BRAVEWRITER.scroller.scrollToPrevItem);
          nextBtn.style.display = 'block';
          prevBtn.style.display = 'block';
        } else {
          nextBtn.style.display = 'none';
          prevBtn.style.display = 'none';
        }
      });
    },

    scrollToNextItem: function(e) {
      const wrapper = e.target.closest('.grid-scroller');
      const scroller = wrapper.querySelector('.grid-scroller-items');
      const distance = scroller.querySelector('.grid-scroller-items > *:nth-child(2)').offsetLeft - scroller.querySelector('.grid-scroller-items > *:nth-child(1)').offsetLeft;

      scroller.scrollBy({left: distance, top: 0, behavior: 'smooth'});
    },

    scrollToPrevItem: function(e) {
      const wrapper = e.target.closest('.grid-scroller');
      const scroller = wrapper.querySelector('.grid-scroller-items');
      const distance = scroller.querySelector('.grid-scroller-items > *:nth-child(2)').offsetLeft - scroller.querySelector('.grid-scroller-items > *:nth-child(1)').offsetLeft;

      scroller.scrollBy({left: -1*distance, top: 0, behavior: 'smooth'});
    },
  },

  // Images Gallery
  // ----------------------------------------------------------------
  imageGallery: {
    init: function() {
      const galleryItems = document.querySelectorAll('.image-gallery-items [data-target]');
      galleryItems.forEach(item => {
        item.removeEventListener('click', BRAVEWRITER.imageGallery.updateFeatured);
        item.addEventListener('click', BRAVEWRITER.imageGallery.updateFeatured);
      });

      const featuredImage = document.querySelectorAll('.image-gallery-featured');
      featuredImage.forEach(image => {
        image.addEventListener('click', BRAVEWRITER.imageGallery.zoomImage);
      });
    },
    updateFeatured: function(e) {
      const featured = document.querySelector(e.target.dataset.target);
      const oldImage = document.querySelector(`${e.target.dataset.target} img:first-child`);
      const newImage = document.createElement('img');
      newImage.src = e.target.src;
      newImage.alt = e.target.alt;
      if(oldImage) {
        const oldClasses = oldImage.classList.values();
        for (const value of oldClasses) {
          newImage.classList.add(value);
        }
      }
      featured.prepend(newImage);

      if(oldImage) {
        oldImage.classList.add('fadeout');
        setTimeout(() => {
          oldImage.remove();
        }, 1000);
      }
    },
    zoomImage: function(e) {
      e.stopPropagation();
      e.preventDefault();
      e.target.classList.toggle('is-zoomed');
    }
  }

};


/* via http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/ */
UTIL = {
 
  fire : function(func,funcname, args){ 
    var namespace = BRAVEWRITER;  // indicate your obj literal namespace here 
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }  
  }, 
 
  loadEvents : function(){
    var bodyId = document.body.id;
 
    // hit up common first.
    UTIL.fire('common');
 
    // do all the classes too.
    $.each(document.body.className.split(/\s+/),function(i,classnm){
      UTIL.fire(classnm);
      UTIL.fire(classnm,bodyId);
    }); 
    UTIL.fire('common','finalize'); 
  } 
 
}; 
 
// kick it all off here 
$(document).ready(UTIL.loadEvents);